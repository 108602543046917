import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from "./App";
import appInfo from "./app-info";
import 'devextreme/integration/jquery';  

axios.defaults.baseURL = appInfo.WebAPIHost;

const app = createApp(App);
app.config.globalProperties.$filters = {
    currency(value) {
      return Intl.NumberFormat().format(value)
    }
}
app.use(VueAxios, axios)
app.use(router);
app.config.globalProperties.$appInfo = appInfo;
app.mount('#app');
