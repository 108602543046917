<template>
  <div>
    <DxDataGrid :height="400"
      :data-source="templateData.data.ProductVariants"
      :show-borders="true" @saving="onSaving"
      @EditorPreparing="EditorPreparing"
      :defer-rendering="false" :column-auto-width="true"
    >
      <DxColumn data-field="Name" caption="Variant Name"/>
      <DxColumn data-field="Alias"/>
      <DxColumn data-field="Serial" caption="Barcode Number"/>
      
      <DxColumn :allow-sorting="false"  caption="Vehicle Type" cell-template="vtTemplate">
      </DxColumn>
      <template #vtTemplate="{ data: cellData }">
          <div v-for="(d, i) in cellData.data.ProductVariantVehicles.map(d=> d.VehicleType.Name)" :key="d+i">
            {{ d }}
          </div>
      </template>
      <DxColumn caption="Brand" data-field="BrandName" calculate-display-value="BrandName"
        :set-cell-value="SetCellValueBrand">
        <DxLookup search-mode="contains" :data-source="BrandDS" display-expr="Text" key-expr="Value" />
      </DxColumn>
      <DxColumn data-field="Image" :editor-options="{
        onFocusIn: () => ChooseImage('variant')
      }" />
      <DxColumn data-field="Tags" caption="Tags #" editor-type="dxTextArea"/>
      <DxColumn data-field="PartNumber" caption="Merk Part Number" />
      <DxColumn data-field="GenuineNumber" />
      <DxColumn data-field="Description" editor-type="dxTextArea" />
      <DxColumn data-field="PackageContent" />
      <DxColumn caption="UOM" data-field="UOMName" calculate-display-value="UOMName" :set-cell-value="SetCellValueUOM">
        <DxLookup search-mode="contains" :data-source="UOMDS" display-expr="Text" key-expr='Value' />
      </DxColumn>
      <DxColumn caption="Class" data-field="ClassName" calculate-display-value="ClassName"
        :set-cell-value="SetCellValueClass">
        <DxLookup search-mode="contains" :data-source="ProductVariantClassDS" display-expr="Text" key-expr="Value" />
      </DxColumn>
      <DxColumn caption="Status" data-field="Status" calculate-display-value="Status"
        :set-cell-value="SetCellValueVariantStatus">
        <DxLookup search-mode="contains" :data-source="ProductVariantStatusDS" display-expr="Text" key-expr="Text" />
      </DxColumn>
        
    </DxDataGrid>
  </div>
</template>
<script>
import { DxColumn, DxDataGrid, DxLookup } from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

export default {
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    DxColumn, DxDataGrid,  DxLookup
  },
  data() {
    return {
      APIHost: this.$appInfo.WebAPIHost,
      id: null,
      UOMDS: null,
      ClassDS: null,
      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === 'search' && typeof (filterValue) === 'string') {
          return [this.dataField, 'contains', filterValue];
        }
        return function (data) {
          return (data.VehicleTypes || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  methods: {
    async cellTemplateTest(container, options) {
      console.log(options)
      console.log(container)
    },
    async cellTemplate(container, options) {
      const noBreakSpace = '\u00A0';
      const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(', ');
      container.textContent = text || noBreakSpace;
      const t = options.value.map(x => x.split('/*')[1])
      container.title = text;
      container.text(t.join(','));
    },
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
    },
    onSelectionChanged(e) {
      e.updateDimensions();
    },
    MenuContextCallBack(contextName, data) {
      const token = this.$CryptoJS.AES.encrypt(data.ID.toString(), this.$appInfo.PrivateKey).toString();
      switch (contextName) {
        case 'ExtMemo':
          this.$router.push({ name: 'ExtMemo', query: { U: token } });
          break;
        case 'IntMemo':
          this.$router.push({ name: 'IntMemo', query: { U: token } });
          break;
      }
    },
  },
  async mounted() {
    this.id = this.$props.templateData.key;
    this.$props.templateData.data.ProductVariants = this.$props.templateData.data.ProductVariants.map(x=> {
      console.log(x)
      x.UOMName = x.UOM.Unit;
      x.ClassName = x.Class.Name;
      return x;
    });
    this.UOMDS = {
      store: createStore({
        key: 'Value',
        loadUrl: `${this.$appInfo.WebAPIHost}/UOM/Selection`,
        onBeforeSend: (_, ajaxOptions) => {
          let filterKey = "";
          if (typeof ajaxOptions.data.filter !== 'undefined') {
            const filter = JSON.parse(decodeURIComponent(ajaxOptions.data.filter))
            filterKey = filter[1];
          }
          ajaxOptions.data.Filter = filterKey ?? '';
          delete ajaxOptions.data.filter;
          ajaxOptions.data.Offset = ajaxOptions.data.skip ?? 0;
          delete ajaxOptions.data.skip;
          ajaxOptions.data.Limit = ajaxOptions.data.take ?? 10;
          delete ajaxOptions.data.take;
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = self.Options.headers;
        },
      }),
      paginate: true,
      pageSize: 20
    };
    this.ClassDS = {
      store: createStore({
        key: 'Value',
        loadUrl: `${this.$appInfo.WebAPIHost}/ProductVariantClass/Selection`,
        onBeforeSend: (_, ajaxOptions) => {
          let filterKey = "";
          if (typeof ajaxOptions.data.filter !== 'undefined') {
            const filter = JSON.parse(decodeURIComponent(ajaxOptions.data.filter))
            filterKey = filter[1];
          }
          ajaxOptions.data.Filter = filterKey ?? '';
          delete ajaxOptions.data.filter;
          ajaxOptions.data.Offset = ajaxOptions.data.skip ?? 0;
          delete ajaxOptions.data.skip;
          ajaxOptions.data.Limit = ajaxOptions.data.take ?? 10;
          delete ajaxOptions.data.take;
          ajaxOptions.xhrFields = { withCredentials: true };
          ajaxOptions.headers = self.Options.headers;
        },
      }),
      paginate: true,
      pageSize: 20
    };
  },
};
</script>
  