<template>
    <div style="padding: 10px">
        <p>Purchase Order</p>
        <DataGrid Key="ID" ModuleName="purchase-order" LoadURL="/" :Height="710" :EnableMasterDetail="true"
            :CustomAddButton="true" :CustomAddButtonCallBack="CustomAddButtonCallBack" :EnableSearch="true"
            :OnStartEditing="EditingStart">
            <template v-slot:Columns>
                <DxColumn data-field="PONumber" />
                <DxColumn data-field="OrderDate" data-type="date" />
                <DxColumn data-field="DeliveryDate" data-type="date" />
                <DxColumn data-field="SupplierID" calculate-display-value="Supplier.Name" caption="Supplier">
                    <DxLookup :remote-operations="true" :data-source="SupplierDS" value-expr="ID" display-expr="Name" />
                </DxColumn>
                <DxColumn data-field="TAC" />
                <DxColumn data-field="Remarks" />
                
            </template>
            <template #MasterDetail="{ data: po }">
                <MasterDetailGrid :template-data="po" @reload="onReload"/>
            </template>
        </DataGrid>
    </div>
</template>
<script>
import DataGrid from '@/components/DatagridView-Component.vue'
import {
    DxColumn
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { WebAPIHost } from '@/utils/config';
import { createStore } from 'devextreme-aspnet-data-nojquery';
const url = `${WebAPIHost}/purchase/order`;
import MasterDetailGrid from './purchase-order-detail.vue';
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});
const SupplierDS = createStore({
    key: 'ID',
    loadUrl: `${WebAPIHost}/supplier`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
export default {
    components: {
        DxColumn,
        DataGrid,
        MasterDetailGrid
    },
    data() {
        return {
            url,
            dataSource,
            orderDateFormat: { year: '2-digit', month: 'narrow', day: '2-digit' },
            SupplierDS
            // saleAmountFormat: { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 }
        };
    },
    methods: {
        CustomAddButtonCallBack() {
            this.$router.push({ name: 'PurchaseOrderNew' });
        },
        gotoAdd() {
            this.$router.push("/packing-list/new")
        },
        onReload() {
            this.$refs.grid.instance.refresh();
        }
    },
    mounted() {
        console.log(this.$refs)
    }
};
</script>
  