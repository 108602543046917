<template>
  <div style="padding: 20px;">
    <h2>Port Of Loading</h2>
    <DxResponsiveBox>
      <DxRow/>
      <DxRow />

      <DxCol />
      <DxCol />
      <DxCol />
      <DxCol />

      <DxItem >
        <DxLocation :row="0" :col="0" />
        <div class="dx-card responsive-paddings">
          <div>
            Stuffing At Depot
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="1" />
        <div class="dx-card responsive-paddings">
          <div>
            At Shipper
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="2" />
        <div class="dx-card responsive-paddings">
          <div>
            Stacked Full
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="3" />
        <div class="dx-card responsive-paddings">
          <div>
            On Board
          </div>
        </div>
      </DxItem>
    </DxResponsiveBox>
    <h2>Port Of Transhipment</h2>
    <DxResponsiveBox>
      <DxRow />
      <DxRow />

      <DxCol />
      <DxCol />
      <DxCol />
      <DxCol />

      <DxItem>
        <DxLocation :row="0" :col="0" />
        <div class="dx-card responsive-paddings">
          <div>
            AT/CY Depot
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="1" />
        <div class="dx-card responsive-paddings">
          <div>
            On Board
          </div>
        </div>
      </DxItem>

    </DxResponsiveBox>
    <h2>Port Of Discharge</h2>
    <DxResponsiveBox>
      <DxRow />
      <DxRow />

      <DxCol />
      <DxCol />
      <DxCol />
      <DxCol />

      <DxItem>
        <DxLocation :row="0" :col="0" />
        <div class="dx-card responsive-paddings">
          <div>
            AT/CY Depot
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="1" />
        <div class="dx-card responsive-paddings">
          <div>
            Stripping At Depot
          </div>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation :row="0" :col="2" />
        <div class="dx-card responsive-paddings">
          <div>
            At Consignee
          </div>
        </div>
      </DxItem>

    </DxResponsiveBox>
  </div>
</template>

<script>
import { DxResponsiveBox, DxCol, DxRow, DxItem, DxLocation } from 'devextreme-vue/responsive-box';
export default {
  components: {
    DxResponsiveBox, DxCol, DxRow, DxItem, DxLocation
  }
}
</script>

<style lang="scss">
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;

  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}
</style>
