<template>
    <div style="padding: 10px">
        <p>Employee</p>
        <DxDataGrid :column-auto-width="true" ref="grid" :show-borders="true" :data-source="dataSource" :remote-operations="true" :height="730">
            <DxColumn data-field="Name">
                <DxRequiredRule message="The PackinNamegListNo field is required." />
            </DxColumn>
            <DxColumn data-field="Phone">
                <DxRequiredRule message="The Phone field is required." />
            </DxColumn>
            <DxColumn data-field="NickName">
                <DxRequiredRule message="The NickName field is required." />
            </DxColumn>
            <DxColumn data-field="Gender"  data-type="boolean">
            </DxColumn>
            <DxColumn data-field="IDCardNumber">
                <DxRequiredRule message="The IDCardNumber field is required." />
            </DxColumn>
            <DxColumn data-field="BirthDate" data-type="date">
                <DxRequiredRule message="The BirthDate field is required." />
            </DxColumn>
            <DxColumn data-field="BirthPlace">
                <DxRequiredRule message="The BirthPlace field is required." />
            </DxColumn>
            <DxColumn data-field="JoinDate" data-type="date">
                <DxRequiredRule message="The JoinDate field is required." />
            </DxColumn>
            <DxColumn data-field="NPWP">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="Email">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="Race">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="Weight">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="Height">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="BloodType">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="Bank">
                <DxRequiredRule message="The NPWP field is required." />
            </DxColumn>
            <DxColumn data-field="BankAccountNo">
                <DxRequiredRule message="The BankAccountNo field is required." />
            </DxColumn>
            <DxColumn data-field="BankBranch">
                <DxRequiredRule message="The BankBranch field is required." />
            </DxColumn>
            <DxColumn data-field="HealthInsuranceNo">
                <DxRequiredRule message="The HealthInsuranceNo field is required." />
            </DxColumn>
            <DxColumn data-field="BPJSNo">
                <DxRequiredRule message="The BPJSNo field is required." />
            </DxColumn>
            <DxColumn data-field="Remarks">
                <DxRequiredRule message="The Remarks field is required." />
            </DxColumn>

            <DxHeaderFilter :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxScrolling mode="virtual" />
            <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
            <DxGrouping :auto-expand-all="false" />
            <DxSummary>
                <DxTotalItem column="Freight" summary-type="sum">
                    <DxValueFormat :precision="2" type="decimal" />
                </DxTotalItem>
                <DxGroupItem column="Freight" summary-type="sum">
                    <DxValueFormat :precision="2" type="decimal" />
                </DxGroupItem>
                <DxGroupItem summary-type="count" />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxTotalItem,
    DxGroupItem,
    DxRequiredRule,
    DxValueFormat,
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { WebAPIHost } from '../../utils/config'
const url = `${WebAPIHost}/employee`;
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxTotalItem,
        DxGroupItem,
        DxRequiredRule,
        DxValueFormat
    },
    data() {
        return {
            url,
            dataSource,
            orderDateFormat: { year: '2-digit', month: 'narrow', day: '2-digit' },
            // saleAmountFormat: { style: 'currency', currency: 'EUR', useGrouping: true, minimumSignificantDigits: 3 }
        };
    },
    methods: {
        gotoAdd() {
            this.$router.push("/packing-list/new")
        }
    },
    mounted() {
        console.log(this.$refs)
    }
};
</script>
  