<template>
    <div style="padding: 10px">
        <p>User Role Group</p>
        <DxDataGrid ref="grid" :show-borders="true" :data-source="dataSource" :remote-operations="true" :height="730">
            <DxColumn data-field="UserID"  calculate-display-value="User.Identifier">
                <DxRequiredRule message="The Rolegroup field is required." />
                <DxLookup
                    :remote-operations="true"
                    :data-source="userDS"
                    value-expr="ID"
                    display-expr="Identifier"
                />
            </DxColumn>
            <DxColumn data-field="RoleGroupID"  calculate-display-value="RoleGroup.Name">
                <DxRequiredRule message="The Rolegroup field is required." />
                <DxLookup
                    :remote-operations="true"
                    :data-source="roleGroupDS"
                    value-expr="ID"
                    display-expr="Name"
                />
            </DxColumn>
           
            <DxHeaderFilter :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxScrolling mode="virtual" />
            <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
            <DxGrouping :auto-expand-all="false" />
            <DxSummary>
                <DxTotalItem column="Freight" summary-type="sum">
                    <DxValueFormat :precision="2" type="decimal" />
                </DxTotalItem>
                <DxGroupItem column="Freight" summary-type="sum">
                    <DxValueFormat :precision="2" type="decimal" />
                </DxGroupItem>
                <DxGroupItem summary-type="count" />
            </DxSummary>
        </DxDataGrid>
    </div>
</template>
<script>
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxTotalItem,
    DxGroupItem,
    DxRequiredRule,
    DxValueFormat,
    DxLookup
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { WebAPIHost } from '../../utils/config'
const url = `${WebAPIHost}/Authentication/UserRoleGroup`;
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});
const roleGroupDS = createStore({
  key: 'ID',
  loadUrl:`${WebAPIHost}/Authentication/RoleGroup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
const userDS = createStore({
  key: 'ID',
  loadUrl:`${WebAPIHost}/Authentication/User`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxTotalItem,
        DxGroupItem,
        DxRequiredRule,
        DxValueFormat,
        DxLookup
    },
    data() {
        return {
            url,
            dataSource,
            roleGroupDS,
            userDS
        };
    },
    methods: {
        gotoAdd() {
            this.$router.push("/packing-list/new")
        }
    }
};
</script>
  