<template>
  <div style="padding: 10px">
    <p>Job Order</p>
    <DxDataGrid  :selection="{ mode: 'single' }" @context-menu-preparing="addMenuItems" :show-borders="true" :data-source="dataSource"
      :remote-operations="true" :height="600">
      <DxToolbar>
        <DxItem name="groupPanel" />
        <DxItem location="after" template="addTemplate" />
      </DxToolbar>
      <template #addTemplate>
        <DxButton icon="add" @click="gotoAdd" />
      </template>
      <DxColumn data-field="Agent">
        <DxRequiredRule message="The Agent field is required." />
      </DxColumn>
      <DxColumn data-field="Customer">
        <DxRequiredRule message="The Agent field is required." />
      </DxColumn>
      <DxColumn data-field="VesselName">
        <DxRequiredRule message="The VesselName field is required." />
      </DxColumn>
      <DxColumn data-field="POL">
        <DxStringLengthRule :max="15" message="The field ShipCountry must be a string with a maximum length of 15." />
      </DxColumn>
      <DxColumn data-field="POD" data-type="">
        <DxStringLengthRule :max="15" message="The field ShipCountry must be a string with a maximum length of 15." />
      </DxColumn>
      <DxColumn data-field="DepartureDate" data-type="date">
        <DxRequiredRule message="The Departure date field is required." />
      </DxColumn>
      <!-- <DxColumn
        data-field="ShipVia"
        caption="Shipping Company"
        data-type="number"
      >
        <DxLookup
          :data-source="shippersData"
          value-expr="Value"
          display-expr="Text"
        />
      </DxColumn> -->
      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: jobOrder }">
        <MasterDetailGrid :template-data="jobOrder" />
      </template>
      <!-- <DxFilterRow :visible="true"/> -->
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxScrolling mode="virtual" />
      <DxGrouping :auto-expand-all="false" />
      <DxSummary>
        <DxTotalItem column="Freight" summary-type="sum">
          <DxValueFormat :precision="2" type="decimal" />
        </DxTotalItem>
        <DxGroupItem column="Freight" summary-type="sum">
          <DxValueFormat :precision="2" type="decimal" />
        </DxGroupItem>
        <DxGroupItem summary-type="count" />
      </DxSummary>
    </DxDataGrid>
  </div>
</template>
<script>
import { DxButton } from 'devextreme-vue/button';
import {
  DxDataGrid,
  DxColumn,
  // DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxSummary,
  // DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  // DxRangeRule,
  DxValueFormat,
  DxToolbar,
  DxItem
} from 'devextreme-vue/data-grid';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import MasterDetailGrid from './job-order-detail.vue';
const url = 'http://localhost:3003/job-order';
const dataSource = createStore({
  key: 'ID',
  loadUrl: `${url}/dx`,
  insertUrl: `${url}/InsertOrder`,
  updateUrl: `${url}/UpdateOrder`,
  deleteUrl: `${url}/DeleteOrder`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
const customersData = createStore({
  key: 'Value',
  loadUrl: `${url}/CustomersLookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
const shippersData = createStore({
  key: 'Value',
  loadUrl: `${url}/ShippersLookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});

export default {
  components: {
    DxDataGrid,
    DxColumn,
    // DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    // DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    // DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    MasterDetailGrid,
    DxToolbar,
    DxItem,
    DxButton
  },
  data() {
    return {
      url,
      customersData,
      shippersData,
      dataSource,
    };
  },
  methods: {
    gotoAdd() {
      this.$router.push("/job-order/new")
    },
    addMenuItems(e) {
      const selecteds = e.component.getSelectedRowsData();
      if(selecteds.length > 0){
        if (!e.items) e.items = [];
        e.items.push({
          text: 'Edit',
          onItemClick: () => {
            this.$router.push(`/job-order/detail/${selecteds[0].ID}`)
          }
        });
        e.items.push({
          text: 'Delete',
          onItemClick: () => {
            console.log(e.column.caption);
          }
        });
      }
    }
  }
};
</script>
