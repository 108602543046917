<template>
    <div id="form-demo" style="padding:20px">
        <DxButton :width="120" text="Save" type="success" styling-mode="contained" @click="submit($event)" />
        <p>Job Order</p>
        <DxForm id="form" :form-data="jobOrder" label-mode="floating" label-location="top" :col-count="3" />

        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem>
                <DxLocation :row="0" :col="0" />
                <div style="padding-right;:10px">
                    <DxDataGrid :ref="jobOrderCostRef" :data-source="dataSourceJobOrderCost" :show-borders="true" :column-auto-width="true">
                        <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
                        <DxColumn data-field="ExpenseID" caption="Expense">
                            <DxLookup :data-source="expenseSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                        <DxColumn data-field="Name" />
                        <DxColumn data-field="Description" />
                        <DxColumn data-field="TotalCost" />
                    </DxDataGrid>
                </div>
            </DxItem>
            <DxItem>
                <DxLocation :row="0" :col="1" />
                <div style="padding-left:10px">
                    <DxDataGrid :ref="containerOrderRef" :data-source="dataContainerOrderCost" :show-borders="true" :column-auto-width="true">
                        <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
                        <DxColumn data-field="VesselID" caption="Vessel">
                            <DxLookup :data-source="vesselSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                        <DxColumn data-field="Size" />
                        <DxColumn data-field="Qty" />
                        <DxColumn data-field="ServiceTypeID" caption="Service Type">
                            <DxLookup :data-source="serviceTypeSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                    </DxDataGrid>
                </div>
            </DxItem>
        </DxResponsiveBox>
    </div>
</template>
<script>
import DxForm from 'devextreme-vue/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxColumn, DxDataGrid, DxEditing, DxLookup, DxStringLengthRule } from 'devextreme-vue/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DxButton from 'devextreme-vue/button';
import {
    DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow,
} from 'devextreme-vue/responsive-box';
const expenseSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/expense/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const vesselSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/vessel/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const serviceTypeSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/service-type/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const jobOrderCostRef = "jobOrderCostRef";
const containerOrderRef = "containerOrderRef";

export default {
    components: {
        DxForm,
        DxColumn, DxDataGrid, DxEditing,
        DxLookup, DxStringLengthRule,
        DxResponsiveBox,
        DxItem,
        DxLocation,
        DxCol,
        DxRow,
        DxButton
    },
    data() {
        return {
            jobOrder: {
                Agent: '',
                Customer: '',
                VesselName: '',
                POL: '',
                POD: '',
                DepartureDate: new Date()
            },
            jobOrderCosts: [],
            containerOrders: [],
            dataSourceJobOrderCost: this.getListJobOrderCostDS(),
            dataContainerOrderCost: this.getListContainerOrderDS(),
            expenseSelectionData,
            vesselSelectionData,
            jobOrderCostRef,
            containerOrderRef,
            serviceTypeSelectionData
        };
    },
    methods: {
        getListJobOrderCostDS() {
            return new DataSource({
                store: new ArrayStore({
                    data: this.jobOrderCosts,
                    key: '_ID',
                }),
            });
        },
        getListContainerOrderDS() {
            return new DataSource({
                store: new ArrayStore({
                    data: this.containerOrders,
                    key: '_ID',
                }),
            });
        },
        submit() {
            const jobOrderCostList =  this.$refs[jobOrderCostRef].instance.getDataSource().items();
            const containerOrdersList =  this.$refs[containerOrderRef].instance.getDataSource().items();
            const jobOrderData = {
                Agent: this.jobOrder.Agent,
                Customer: this.jobOrder.Customer,
                VesselName:this.jobOrder.VesselName,
                POL: this.jobOrder.POL,
                POD: this.jobOrder.POD,
                DepartureDate: this.jobOrder.DepartureDate,
                ListContainerOrder: containerOrdersList,
                ListJobOrderCost: jobOrderCostList
            }
            console.log(jobOrderData)
            this.axios.post("/job-order", jobOrderData);
        }
    }
};
</script>
<style scoped>
.widget-container {
    margin-right: 320px;
    padding: 20px;
    max-width: 550px;
    min-width: 300px;
}

#form {
    margin-top: 25px;
}

.options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 260px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
  