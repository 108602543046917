<template>
    <div>
        <DxResponsiveBox>
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem>
                <DxLocation :row="0" :col="0" />
                <div style="padding: 10px">
                    <DxDataGrid :data-source="dataSourceJobOrderCost" :show-borders="true" :column-auto-width="true">
                        <DxColumn data-field="Name" />
                        <DxColumn data-field="TotalCost" />
                        <DxColumn data-field="ExpenseClass.ExpenseClassID" />
                    </DxDataGrid>
                </div>
            </DxItem>
            <DxItem>
                <DxLocation :row="0" :col="1" />
                <div style="padding: 10px">
                    <DxDataGrid :data-source="dataSourceContainerOrder" :show-borders="true" :column-auto-width="true">
                        <DxColumn data-field="Description" />
                    </DxDataGrid>
                </div>
            </DxItem>
        </DxResponsiveBox>
    </div>
</template>
  
<script>
import { DxColumn, DxDataGrid } from 'devextreme-vue/data-grid';
import { DxResponsiveBox, DxCol, DxRow, DxLocation, DxItem } from 'devextreme-vue/responsive-box';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

export default {
    components: {
        DxResponsiveBox,
        DxCol,
        DxRow,
        DxLocation,
        DxItem,
        DxDataGrid,
        DxColumn
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },

    },
    data() {
        return {
            dataSourceJobOrderCost: this.getListJobOrderCostDS(),
            dataSourceContainerOrder: this.getListContainerOrderDS()
        };
    },
    methods: {
        completedValue(rowData) {
            return rowData.Status === 'Completed';
        },
        getListJobOrderCostDS() {
            return new DataSource({
                store: new ArrayStore({
                    data: this.templateData.data.ListJobOrderCost,
                    key: 'ID',
                }),
            });
        },
        getListContainerOrderDS() {
            return new DataSource({
                store: new ArrayStore({
                    data: this.templateData.data.ListContainerOrder,
                    key: 'ID',
                }),
            });
        },
    }
};
</script>
  