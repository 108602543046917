<template>
    <div>
        <DxForm>
            <DxGroupItemForm :col-count="10" :caption="PageTitle">
                <DxItemForm>
                    <DxDataGrid @cell-prepared="onCellPrepared" @init-new-row="initNewRow" key-expr="ID"
                        @editing-start="editingStart" @selection-changed="selectedItem" :column-auto-width="true"
                        :allow-column-resizing="true" :ref="grid" :show-borders="true" :data-source="dataSource"
                        :remote-operations="true" :height="Height" :hover-state-enabled="true" @exporting="onExporting"
                        @context-menu-preparing="addMenuItems">
                        <DxColumnChooser :enabled="true" />
                        <slot name="Columns"></slot>
                        <slot name="vt"/>
                        <template #ImageTemplate="{ data }">
                            <!-- <img :src="()=> {
                                return data.data.Image.split(',').map(d=> $appInfo.WebAPIHost+'/uploads/'+d.Image)
                            }" class="corners"> -->
                            <DxGallery id="gallery"
                                :data-source="data.data.Image.split(',').map(d => $appInfo.WebAPIHost + '/uploads/' + d)"
                                :slideshow-delay="1000" :show-nav-buttons="false" :show-indicator="false" :loop="true"
                                :height="50" :width="50" />
                        </template>
                        <template #PurchasePriceTemplate="{ data: response }">
                            <slot name="PurchasePrice" :data="response"></slot>
                        </template>
                        <template #TotalTemplate="{ data: response }">
                            <slot name="Total" :data="response"></slot>
                        </template>
                        <DxSelection mode="multiple" :allow-select-all="true" select-all-mode="page" />
                        <DxSorting mode="none" />
                        <DxSearchPanel :width="240" :visible="EnableSearch" />
                        <DxExport :enabled="EnableExport" :formats="['xlsx', 'pdf']" />
                        <DxFilterRow :visible="EnableFilterRow" />
                        <DxHeaderFilter :visible="EnableFilterHeader" />
                        <DxGroupPanel :visible="EnableGroup" />
                        <DxPaging :page-size="20" />
                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" display-mode="compact"
                            :show-page-size-selector="showPageSizeSelector" :show-info="showInfo"
                            :show-navigation-buttons="showNavButtons" />
                        <DxEditing :allow-adding="EnableAdding" :allow-updating="EnableUpdating"
                            :allow-deleting="EnableDeleting" :mode="DatagridMode" />
                        <DxToolbar>

                            <DxItem location="before" template="organization" v-if="OrgSelection" />
                            <!-- <DxItem location="before" template="search" v-if="EnableSearch" /> -->
                            <DxItem name="exportButton" />
                            <!-- <DxItem name="refreshPanel" /> -->
                            <DxItem name="searchPanel" />
                            <DxItem name="groupPanel" />
                            <DxItem location="before" template="refreshTemplate" />
                            <DxItem :options="deleteButtonOptions" location="before" widget="dxButton" />
                            <!-- <DxItem location="before" name="filter" template="filterTemplate" v-if="FilterStatus.length> 0"/> -->
                            <DxItem location="after" name="columnChooserButton" />
                            <DxItem location="after" name="addRowButton" template="addTemplate" v-if="CustomAddButton"
                                @click="CustomAddButtonCallBack" />
                            <DxItem location="after" name="addRowButton" v-else />
                        </DxToolbar>

                        <template #refreshTemplate>
                            <DxButton icon="refresh" @click="() => refresh(null)" />
                        </template>
                        <template #addTemplate>
                            <DxButton icon="add" @click="gotoAdd" />
                        </template>
                        <template #organization>
                            <div style="padding-right:20px;">
                                <DxSelectBox :searchEnabled='true' width="300" :data-source="orgs" display-expr="Name"
                                    value-expr="Database" value="AS_WNS" @value-changed="onChange" />
                            </div>
                        </template>
                       
                        <template #statusTemplate>
                            <slot name="status" />
                        </template>
                        <template #filterTemplate>
                            <DxLookup width="300" @value-changed="FilterStatusAction" :items="FilterStatus"
                                :value="FilterStatus[0]" display-expr="Text" value-expr="Value">
                                <DxDropDownOptions :show-title="false" />
                            </DxLookup>
                        </template>
                        <DxMasterDetail :enabled="$props.EnableMasterDetail" template="MasterDetail" />
                        <template #MasterDetail="{ data: response }">
                            <slot name="MasterDetail" :data="response"></slot>
                        </template>
                        <DxGrouping :auto-expand-all="false" />
                        <DxSummary>
                            <DxTotalItem column="Freight" summary-type="sum">
                                <DxValueFormat :precision="2" type="decimal" />
                            </DxTotalItem>
                            <DxGroupItem column="Freight" summary-type="sum">
                                <DxValueFormat :precision="2" type="decimal" />
                            </DxGroupItem>
                            <DxGroupItem summary-type="count" />
                        </DxSummary>
                    </DxDataGrid>
                </DxItemForm>
            </DxGroupItemForm>
        </DxForm>
        <DxPopup :width="660" :height="540" :show-title="true" title="Information" :drag-enabled="true"
            :hide-on-outside-click="true" v-model:visible="PopupVisible">
            <template #content>
                <div class="popup-property-details center">
                    {{ Message }}
                </div>
            </template>
        </DxPopup>
    </div>
</template>
<script>
import {
    DxLookup,
    DxDropDownOptions,
} from 'devextreme-vue/lookup';
import DxPopup from 'devextreme-vue/popup';
import DxGallery from 'devextreme-vue/gallery';
import {
    DxDataGrid,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxExport,
    DxToolbar,
    DxItem,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxSummary,
    DxTotalItem,
    DxGroupItem,
    DxValueFormat,
    DxMasterDetail,
    DxSorting,
    DxSelection,
    DxColumnChooser
} from 'devextreme-vue/data-grid';
import notify from 'devextreme/ui/notify';
import { GetContextMenu } from './context-menu';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxButton } from 'devextreme-vue/button';
import DxForm, { DxItemForm, DxGroupItem as DxGroupItemForm } from 'devextreme-vue/form';
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
export default {
    components: {
        DxLookup,
        DxDropDownOptions,
        DxSorting,
        DxDataGrid,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxExport,
        DxToolbar,
        DxItem,
        DxPager,
        DxPaging,
        DxSearchPanel,
        DxSummary,
        DxTotalItem,
        DxGroupItem,
        DxValueFormat,
        DxSelectBox,
        DxMasterDetail,
        DxSelection,
        DxButton,
        DxColumnChooser,
        DxGroupItemForm,
        DxItemForm,
        DxForm,
        DxPopup,
        DxGallery
        // DxTextBox
    },
    props: {
        Key: {
            type: String,
            default: () => "",
        },
        PageTitle: {
            type: String,
            default: () => "",
        },
        ModuleName: {
            type: String,
            default: () => "",
        },
        LoadURL: {
            type: String,
            default: () => "",
        },

        CustomLoadURL: {
            type: Boolean,
            default: () => false
        },
        InsertURL: {
            type: String,
            default: () => "",
        },
        UpdateURL: {
            type: String,
            default: () => "",
        },
        RemoveURL: {
            type: String,
            default: () => "",
        },
        EnableMasterDetail: {
            type: Boolean,
            default: () => false
        },
        ExtendParams: {
            type: String,
            default: () => null,
        },
        OrgSelection: {
            type: Boolean,
            default: () => false
        },
        OrgDefault: {
            type: String,
            default: () => ''
        },
        EnableGroup: {
            type: Boolean,
            default: () => false
        },
        EnableFilterHeader: {
            type: Boolean,
            default: () => false
        },
        EnableFilterRow: {
            type: Boolean,
            default: () => false
        },
        EnableSearch: {
            type: Boolean,
            default: () => false
        },
        EnableExport: {
            type: Boolean,
            default: () => false
        },
        Height: {
            type: Number,
            default: () => 600
        },
        EnableAdding: {
            type: Boolean,
            default: () => true
        },
        EnableUpdating: {
            type: Boolean,
            default: () => true
        },
        EnableDeleting: {
            type: Boolean,
            default: () => true
        },
        DatagridMode: {
            type: String,
            default: () => 'row'
        },

        CustomUpdateCallBack: {
            type: Function,
            default: () => null
        },
        CustomUpdate: {
            type: Boolean,
            default: () => false
        },

        CustomInsertCallBack: {
            type: Function,
            default: () => null
        },
        CustomInsert: {
            type: Boolean,
            default: () => false
        },
        CustomRemoveCallBack: {
            type: Function,
            default: () => null
        },
        CustomRemove: {
            type: Boolean,
            default: () => false
        },
        RefreshDataGrid: {
            type: Function,
            default: () => () => { }
        },
        MenuContextCallBack: {
            type: Function,
            default: () => { }
        },
        SelectionCallBack: {
            type: Function,
            default: () => { }
        },
        FilterFixed: {
            type: Boolean,
            default: () => false
        },
        OnInitNewRow: {
            type: Function,
            default: () => { }
        },
        OnStartEditing: {
            type: Function,
            default: () => { }
        },
        ContextMenuName: {
            type: String,
            default: () => ''
        },
        CustomAddButton: {
            type: Boolean,
            default: () => false
        },
        CustomAddButtonCallBack: {
            type: Function,
            default: () => { }
        },
        Reference: {
            type: String,
            default: () => 'grid'
        },
        OnCellPrepared: {
            type: Function,
            default: () => null
        },
        FilterStatus: {
            type: Array,
            default: () => []
        },
        FilterSelectionCallBack: {
            type: Function,
            default: () => null
        },
        DeletePermanent: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        const _host = this.$appInfo.WebAPIHost;
        const _loadURL = this.$props.CustomLoadURL ? this.$props.LoadURL : `${_host}/${this.$props.ModuleName}${this.$props.LoadURL}`
        const _removeURL = this.$props.CustomLoadURL ? this.$props.RemoveURL : `${_host}/${this.$props.ModuleName}${this.$props.RemoveURL}`
        const _updateURL = this.$props.CustomLoadURL ? this.$props.UpdateURL : `${_host}/${this.$props.ModuleName}${this.$props.UpdateURL}`
        const _insertURL = this.$props.CustomLoadURL ? this.$props.InsertURL : `${_host}/${this.$props.ModuleName}${this.$props.InsertURL}`
        return {
            deleteButtonOptions: {
                icon: 'trash',
                onClick: async () => {
                    const grid = this.$refs[this.grid].instance;
                    const rows = grid.getSelectedRowsData();
                    const ids = rows.map(x=> x.ID);
                    await this.removeMultiple(ids);
                    notify('Add button has been clicked!');
                },
            },
            APIHost: this.$appInfo.WebAPIHost,
            host: `${_host}/${this.$props.ModuleName}`,
            baseHost: _host,
            loadURL: _loadURL,
            insertURL: _insertURL,
            removeURL: _removeURL,
            updateURL: _updateURL,
            dataSource: null,
            pageSizes: [5, 10, 20, 50, 100, 200, 300, 500],
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            items: null,
            dbName: this.$props.OrgDefault,
            orgs: [],
            Options: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(this.$appInfo.Conf.Token)}`,
                    'Content-Type': 'application/json'
                },
            },
            grid: this.$props.Reference,
            Filter: '',
            ContextMenuSelectedItem: null,
            Version: 0,
            PopupVisible: false,
            Message: 'Data'
        };
    },
    methods: {
        onCellPrepared: function (e) {
            if (e.rowType === "data" && e.column.command === "expand") {
                this.$props.OnCellPrepared(e);
            }
        },
        editingStart(e) {
            this.Version = e.data.Version
            this.$props.OnStartEditing(e);
        },
        initNewRow(e) {
            this.$props.OnInitNewRow(e)
        },
        refresh(url) {
            if (url) {
                this.loadURL = url;
            }
            this.$refs[this.grid].instance.refresh();
        },
        addMenuItems(e) {
            if (e.target == 'content') {
                if (e.row) {
                    const { data } = e.row;
                    this.ContextMenuSelectedItem = data;
                    if (!e.items) e.items = GetContextMenu(this.$props.ContextMenuName, this.onClickMenuContext);
                }
            }
        },
        selectedItem(e) {
            this.SelectionCallBack(e);
        },
        itemClick(e) {
            if (!e.itemData.items) {
                notify(`The "${e.itemData.text}" item was clicked`, 'success', 1500);
            }
        },
        onExporting(e) {
            if (e.format === 'xlsx') {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Main Sheet');
                exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function (options) {
                        const excelCell = options;
                        excelCell.font = { name: 'Arial', size: 12 };
                        excelCell.alignment = { horizontal: 'left' };
                    }
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                    });
                });
                e.cancel = true;
            }
            else if (e.format === 'pdf') {
                const doc = new jsPDF();
                exportDataGridToPdf({
                    jsPDFDocument: doc,
                    component: e.component
                }).then(() => {
                    doc.save('DataGrid.pdf');
                })
            }
        },
        onChange(e) {
            this.dbName = e.value;
            this.$refs[this.grid].instance.refresh();
        },
        FilterStatusAction(e) {
            this.filterStatus = e.value;
            this.$refs[this.grid].instance.refresh();
        },
        async load(loadOptions) {
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    if (i === 'filter') {
                        const options = typeof (loadOptions[i][0]) === 'string' ? loadOptions[i] : loadOptions[i][0];
                        params += `FilterBy=all&Filter=${options[2]}&`;
                    } else if (i === 'skip') {
                        params += `Offset=${JSON.stringify(loadOptions[i])}&`;
                    } else if (i === 'take') {
                        params += `Limit=${JSON.stringify(loadOptions[i])}&`;
                    } else {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                }
            });
            if (this.$props.ExtendParams) {
                params += this.$props.ExtendParams + '&';
            }
            if (this.dbName) {
                params += `DBName=${this.dbName}&`;
            }
            if (this.OrgDefault) {
                params += `DBName=${this.OrgDefault}&`;
            }

            if (this.filterStatus) {
                params += `FilterStatus=${this.filterStatus}&`;
            }
            params = params.slice(0, -1);
            const response = await fetch(`${this.loadURL}${params}`, this.Options)
            if (response.status !== 200 && response.status !== 201) {
                const res = await response.json()
                throw new Error(res.Message)
            } else {
                const data = await response.json()
                return {
                    data: data.Items,
                    totalCount: data.NumberOfRows,
                    summary: data.summary,
                    groupCount: data.groupCount,
                };
            }
        },
        insert(data) {
            if (this.$props.CustomInsert) {
                return this.$props.CustomInsertCallBack(data)
            } else {
                return fetch(`${this.insertURL}`, {
                    method: 'POST',
                    ...this.Options,
                    body: JSON.stringify(data)
                }).catch(() => { throw new Error('Data Loading Error'); });
            }
        },
        async update(key, data) {
            const self = this;
            if (this.$props.CustomUpdate) {
                return this.$props.CustomUpdateCallBack(key, data)
            } else {
                const response = await fetch(`${this.updateURL}/${key}`, {
                    method: 'PUT',
                    ...self.Options,
                    body: JSON.stringify({
                        ...data,
                        Version: self.Version
                    })
                });
                const res = await response.json()
                console.log(res)
                if (response.status !== 200 && response.status !== 201) {
                    throw new Error(res.Message)
                }
                return response;
            }
        },
        remove(key) {
            const self = this;
            if (this.$props.CustomRemove) {
                return this.$props.CustomRemoveCallBack(key)
            } else {
                return fetch(`${this.removeURL}/${key}?Permanent=${this.$props.DeletePermanent}`, {
                    method: 'DELETE',
                    ...self.Options
                }).catch(() => { throw new Error('Data Loading Error'); });
            }
        },
        removeMultiple(body) {
            const self = this;
            return fetch(`${this.removeURL}/Multiple?Permanent=${this.$props.DeletePermanent}`, {
                method: 'DELETE',
                body: JSON.stringify(body),
                ...self.Options
            }).catch(() => { throw new Error('Data Loading Error'); });
        },
        onClickMenuContext(e) {
            this.$props.MenuContextCallBack(e, this.ContextMenuSelectedItem)
        }
    },
    mounted() {
        const self = this;
        this.orgs = createStore({
            key: 'Value',
            loadUrl: `${self.APIHost}/setting/organization/dx`,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.xhrFields = { withCredentials: true };
            },
        });
        this.dataSource = new DataSource({
            store: new CustomStore({
                key: self.$props.Key,
                load: self.load,
                insert: self.insert,
                update: self.update,
                remove: self.remove
            }),
        });
    }
};
</script>
<style>
.corners {
    border-radius: 15px;
    max-width: 120px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border: 1px solid red;
}
</style>