<template>
    <div style="padding: 10px">
        <DataGrid PageTitle="Product" Key="ID" ModuleName="Product" LoadURL="/List" InsertURL="/Create" UpdateURL="/Modify"
            RemoveURL="/Delete" :DeletePermanent="false" :Height="730" :EnableSearch="true"
            ContextMenuName="Product"
            :MenuContextCallBack="MenuContextCallBack"
            :CustomAddButton="true"
            :CustomAddButtonCallBack="CustomAddButtonCallBack"
            :EnableMasterDetail="true"
        >
            <template v-slot:Columns>
                <DxColumn
                    :allow-sorting="false"
                    data-field="Image"
                    cell-template="ImageTemplate"
                />
                <DxColumn data-field="Name" caption="Item Name"></DxColumn>
                <DxColumn data-field="Alias" caption="Alias"></DxColumn>
                <DxColumn data-field="Tags" caption="Tags"></DxColumn>
                <DxColumn data-field="Description" caption="Description"></DxColumn>
                <DxColumn data-field="CategoryID" calculate-display-value="Category.Name" caption="Category">
                    <DxLookup search-mode="contains" :min-search-length="2" :show-data-before-search="true"
                        :remote-operations="true" page-load-mode="nextButton" next-button-text="More"
                        :data-source="CategoryDS" value-expr="Value" display-expr="Text" />
                </DxColumn>
                <DxColumn type="buttons">
                    <DxButton name="edit" @click="CustomUpdateCallBack" />
                    <DxButton name="delete" />
                </DxColumn>
                <!-- <DxColumn data-field="CreatedBy" :min-width="107" :allow-editing="false"></DxColumn>
                <DxColumn data-field="CreatedTime" data-type="date" format="dd/MM/yyyy hh:mm" :allow-editing="false"></DxColumn>
                <DxColumn data-field="ModifiedBy" :min-width="107" :allow-editing="false"></DxColumn>
                <DxColumn data-field="ModifiedTime" data-type="date" format="dd/MM/yyyy hh:mm" :allow-editing="false"></DxColumn>
                <DxColumn data-field="DeletedBy" :min-width="107" allow-editing="false"></DxColumn>
                <DxColumn data-field="DeletedTime" data-type="date" format="dd/MM/yyyy hh:mm" :allow-editing="false"></DxColumn> -->
            </template>
            <template #MasterDetail="{ data: PR }">
                <ProductVariant :template-data="PR" @reload="onReload" />
            </template>
        </DataGrid>
    </div>
</template>
<script>
import { DxColumn, DxLookup, DxButton } from 'devextreme-vue/data-grid';
import DataGrid from '../../components/DatagridView-Component.vue'
import { createStore } from 'devextreme-aspnet-data-nojquery';
import ProductVariant from './product-variant-component.vue'
export default {
    components: {
        DataGrid,
        DxColumn,
        DxLookup,
        ProductVariant,
        DxButton
    },
    data() {
        return {
            APIHost: this.$appInfo.WebAPIHost,
            CategoryDS: null,
            BrandDS: null,
            Headers: {
                Authorization: `Bearer ${localStorage.getItem("Sinarjaya-Token")}`,
                'Content-Type': 'application/json'
            },
        };
    },
    methods: {
        CustomAddButtonCallBack() {
            this.$router.push({ name: 'ProductItemNew' });
        },
        CustomUpdateCallBack(e) {
            this.$router.push({ name: 'ProductItemEdit', params: {id: e.row.data.ID} });
        },
        MenuContextCallBack(contextName, data) {
            const token = this.$CryptoJS.AES.encrypt(data.ID.toString(), this.$appInfo.PrivateKey).toString();
            switch (contextName) {
                case 'ExtMemo':
                    this.$router.push({ name: 'ExtMemo', query: { U: token } });
                    break;
                case 'IntMemo':
                    this.$router.push({ name: 'IntMemo', query: { U: token } });
                    break;
            }
        },
    },
    async mounted() {
        const self = this;
        this.CategoryDS = {
            store: createStore({
                key: 'Value',
                loadUrl: `${self.APIHost}/ProductCategory/Selection`,
                onBeforeSend: (_, ajaxOptions) => {
                    ajaxOptions.data.Filter = ajaxOptions.data.filter;
                    delete ajaxOptions.data.filter;
                    ajaxOptions.data.Limit = ajaxOptions.data.take;
                    delete ajaxOptions.data.take;
                    ajaxOptions.data.Offset = ajaxOptions.data.skip;
                    delete ajaxOptions.data.skip;
                    ajaxOptions.xhrFields = { withCredentials: true };
                    ajaxOptions.headers = self.Headers;
                }
            }),
            paginate: true,
            pageSize: 20
        };
        this.BrandDS = {
            store: createStore({
                key: 'Value',
                loadUrl: `${self.APIHost}/Brand/Selection`,
                onBeforeSend: (_, ajaxOptions) => {
                    ajaxOptions.data.Filter = ajaxOptions.data.filter;
                    delete ajaxOptions.data.filter;
                    ajaxOptions.data.Limit = ajaxOptions.data.take;
                    delete ajaxOptions.data.take;
                    ajaxOptions.data.Offset = ajaxOptions.data.skip;
                    delete ajaxOptions.data.skip;
                    ajaxOptions.xhrFields = { withCredentials: true };
                    ajaxOptions.headers = self.Headers;
                }
            }),
            paginate: true,
            pageSize: 20
        };
    },
};
</script>
  