export default [
  {
    text: "Privilege",
    icon: "key",
    items: [
      {
        text: "User",
        path: "/user"
      },
      {
        text: "Role",
        path: "/role"
      },
      // {
      //   text: "Role Group",
      //   path: "/role-group"
      // },
      // {
      //   text: "Assign Role",
      //   path: "/assign-role"
      // }
    ]
  },
]