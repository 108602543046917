export default [
    {
        text: "Product",
        icon: "orderedlist",
        items: [
              {
                text: "Item",
                path: "/product-item"
              },
              {
                text: "Variant",
                path: "/product-variant"
              },
              {
                text: "Stock",
                path: "/stock"
              },
              {
                text: "Price",
                path: "/price"
              },
              {
                text: "Media",
                path: "/media"
              },
              
        ]
    }
]