<template>
    <div>
        <DxResponsiveBox>
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem>
                <DxLocation :row="0" :col="0" />
                <div style="padding: 10px">
                    <DxDataGrid :data-source="datasource" :show-borders="true" :column-auto-width="true">
                        <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
                        <DxColumn data-field="ProductItemID"  calculate-display-value="ProductItem.ItemName" caption="Item">
                            <DxRequiredRule message="The Role field is required." />
                            <DxLookup
                                :remote-operations="true"
                                :data-source="productDS"
                                value-expr="ID"
                                display-expr="ItemName"
                            />
                        </DxColumn>
                        <DxColumn data-field="Category">
                            <DxRequiredRule message="The Role field is required." />
                            <DxLookup
                                :remote-operations="true"
                                :data-source="productCategoryDS"
                                value-expr="Name"
                                display-expr="Name"
                            />
                        </DxColumn>
                        <DxColumn data-field="Quantity" />
                        <DxColumn data-field="Amount" />
                        <DxColumn data-field="Subtotal" />
                        <DxColumn data-field="Remarks" />
                        
                        <DxColumn data-field="Purpose" />
                        
                    </DxDataGrid>
                </div>
            </DxItem>
           
        </DxResponsiveBox>
    </div>
</template>
  
<script>
import { DxColumn, DxDataGrid, DxEditing, DxLookup } from 'devextreme-vue/data-grid';
import { DxResponsiveBox, DxCol, DxRow, DxLocation, DxItem } from 'devextreme-vue/responsive-box';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { WebAPIHost } from '../../utils/config';
import { createStore } from 'devextreme-aspnet-data-nojquery';
const url = `${WebAPIHost}/purchase/request-detail`;

const productDS = createStore({
  key: 'ID',
  loadUrl:`${WebAPIHost}/product`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
const productCategoryDS = createStore({
  key: 'ID',
  loadUrl:`${WebAPIHost}/product-category`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true };
  },
});
export default {
    components: {
        DxResponsiveBox,
        DxCol,
        DxRow,
        DxLocation,
        DxItem,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxLookup
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },

    },
    data() {
        return {
            datasource: this.getDS(),
            productDS,
            productCategoryDS
        };
    },
    methods: {
        getDS() {
            const self = this;
            return new DataSource({
                store: new ArrayStore({
                    data: this.templateData.data.PurchaseRequestDetails,
                    key: 'ID',
                    onInserting(value) {
                         fetch(`${url}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                ...value,
                                PurchaseRequestID: self.templateData.data.ID
                            })
                        })
                        .then(()=> {
                            self.$emit('reload')
                        })
                        .catch((e) => { console.log(e) });
                    },
                    onUpdating(key, data){
                        console.log(data)
                        fetch(`${url}/${key}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data)
                        })
                        .then(()=> {
                            self.$emit('reload')
                        })
                        .catch((e) => { console.log(e) });
                    },
                    onRemoving(key){
                        fetch(`${url}/${key}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(()=> {
                            self.$emit('reload')
                        })
                        .catch((e) => { console.log(e) });
                    }
                },
            ),
            });
        },
    }
};
</script>
  