export default [
    {
        text: "Sales",
        icon: "box",
        items: [
            {
                text: "Sales Order",
                path: "/packing-list/new"
            },
            {
                text: "Sales Invoice",
                path: "/packing-list"
            }
        ]
    }
]