<template>
  <div style="padding: 10px">
    <DataGrid PageTitle="Brand" Key="ID" ModuleName="Brand" LoadURL="/List" InsertURL="/Create" UpdateURL="/Modify"
      RemoveURL="/Delete" :DeletePermanent="false" :Height="730" :EnableSearch="true" :FilterStatus="CustomFilter"
      ContextMenuName="UOM" :MenuContextCallBack="MenuContextCallBack">
      <template v-slot:Columns>
        <DxColumn data-field="Name" caption="Name"></DxColumn>
        <DxColumn data-field="Image" caption="Image" :editor-options="{
          onFocusIn: () => ChooseImage('variant')
        }"></DxColumn>
        <DxColumn data-field="CreatedBy" :min-width="107"></DxColumn>
        <DxColumn data-field="CreatedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
        <DxColumn data-field="ModifiedBy" :min-width="107"></DxColumn>
        <DxColumn data-field="ModifiedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
        <DxColumn data-field="DeletedBy" :min-width="107"></DxColumn>
        <DxColumn data-field="DeletedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
      </template>
    </DataGrid>
    <DxPopup v-model:visible="FileChoosing" :drag-enabled="false" :hide-on-outside-click="true" :show-close-button="false"
      :show-title="true" container=".dx-viewport" title="Information">
      <div>
        <DxFileManager :file-system-provider="remoteProvider" :on-selected-file-opened="SelectedImage"
          current-path="Widescreen" height="750">
          <DxPermissions :create="true" :copy="true" :move="true" :delete="true" :rename="true" :upload="true"
            :download="true" />
        </DxFileManager>
      </div>
    </DxPopup>
  </div>
</template>
<script>
import { DxColumn } from 'devextreme-vue/data-grid';
import DataGrid from '../../components/DatagridView-Component.vue'
import { DxPopup } from 'devextreme-vue/popup';
import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
export default {
  components: {
    DataGrid,
    DxColumn,
    DxPopup,
    DxFileManager, DxPermissions
  },
  data() {
    return {
      APIHost: this.$appInfo.WebAPIHost,
      remoteProvider: null,
      FileChoosing: false
    };
  },
  beforeMount() {
    this.remoteProvider = new RemoteFileSystemProvider({
      endpointUrl: `${this.APIHost}/Media/Remote`,
    });
  },
  methods: {
    ChooseImage(e) {
      if (e == 'variant') {
        this.IsVariantImage = true;
      }
      this.FileChoosing = true;
    },
    SelectedImage(e) {
      if (this.IsVariantImage) {
        this.Image.row.data.Image = e.file.path;
        this.$refs['Items'].instance.refresh();
      } else {
        this.FormDataModel.Image = e.file.path;
      }
      this.FileChoosing = false;
    },
    MenuContextCallBack(contextName, data) {
      const token = this.$CryptoJS.AES.encrypt(data.ID.toString(), this.$appInfo.PrivateKey).toString();
      switch (contextName) {
        case 'ExtMemo':
          this.$router.push({ name: 'ExtMemo', query: { U: token } });
          break;
        case 'IntMemo':
          this.$router.push({ name: 'IntMemo', query: { U: token } });
          break;
      }
    },
  },
  async mounted() {
  },
};
</script>
  