<template>
    <div id="form-demo" style="padding:20px">
        <DxButton :width="120" text="Save" type="success" styling-mode="contained" @click="submit($event)" />
        <p>Job Order</p>
        <DxForm id="form" :form-data="jobOrder" label-mode="floating" label-location="top" :col-count="3" />

        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem>
                <DxLocation :row="0" :col="0" />
                <div style="padding-right;:10px">
                    <DxDataGrid 
                    @row-removed="onDeleteRowJobOrderCost"
                    @row-inserted="onInsertRowJobOrderCost"
                    :ref="jobOrderCostRef" :data-source="jobOrderCosts" :show-borders="true" :column-auto-width="true">
                        <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
                        <DxColumn data-field="ExpenseID" caption="Expense">
                            <DxLookup :data-source="expenseSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                        <DxColumn data-field="Name" />
                        <DxColumn data-field="Description" />
                        <DxColumn data-field="TotalCost" />
                    </DxDataGrid>
                </div>
            </DxItem>
            <DxItem>
                <DxLocation :row="0" :col="1" />
                <div style="padding-left:10px">
                    {{containerOrdersRemove}}
                    <DxDataGrid
                    @row-inserted="onInsertRowContainerOrder"
                    @row-removed="onDeleteRowContainerOrder" :ref="containerOrderRef" :data-source="containerOrders" :show-borders="true" :column-auto-width="true">
                        <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="row" />
                        <DxColumn data-field="VesselID" caption="Vessel">
                            <DxLookup :data-source="vesselSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                        <DxColumn data-field="Size" />
                        <DxColumn data-field="Qty" />
                        <DxColumn data-field="ServiceTypeID" caption="Service Type">
                            <DxLookup :data-source="serviceTypeSelectionData" value-expr="Value" display-expr="Text" />
                            <DxStringLengthRule :max="5"
                                message="The field Customer must be a string with a maximum length of 5." />
                        </DxColumn>
                    </DxDataGrid>
                </div>
            </DxItem>
        </DxResponsiveBox>
    </div>
</template>
<script>
import DxForm from 'devextreme-vue/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxColumn, DxDataGrid, DxEditing, DxLookup, DxStringLengthRule } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import {
    DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow,
} from 'devextreme-vue/responsive-box';
const expenseSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/expense/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const vesselSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/vessel/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const serviceTypeSelectionData = createStore({
    key: 'Value',
    loadUrl: `http://localhost:3003/service-type/selections/dx`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const jobOrderCostRef = "jobOrderCostRef";
const containerOrderRef = "containerOrderRef";

export default {
    components: {
        DxForm,
        DxColumn, DxDataGrid, DxEditing,
        DxLookup, DxStringLengthRule,
        DxResponsiveBox,
        DxItem,
        DxLocation,
        DxCol,
        DxRow,
        DxButton
    },
    data() {
        return {
            jobOrder: {
                Agent: '',
                Customer: '',
                VesselName: '',
                POL: '',
                POD: '',
                DepartureDate: new Date()
            },
            jobOrderTemp: {},
            jobOrderCosts: [],
            jobOrderCostsTemp: [],
            jobOrderCostRemove: [],
            containerOrders: [],
            containerOrdersTemp: [],
            containerOrdersRemove: [],
            expenseSelectionData,
            vesselSelectionData,
            jobOrderCostRef,
            containerOrderRemove: [],
            containerOrderRef,
            serviceTypeSelectionData
        };
    },
    methods: {
        onDeleteRowJobOrderCost(e){
            const find = this.jobOrderCostsTemp.findIndex((d)=> d.ID === e.data.ID );
            if(find > -1)
                this.jobOrderCostRemove.push(e.data);
        },
        onInsertRowJobOrderCost(e){
            this.jobOrderTemp.ListJobOrderCost.push(e.data);
        },
        onInsertRowContainerOrder(e){
            this.jobOrderTemp.ListContainerOrder.push(e.data);
        },
        onDeleteRowContainerOrder(e){
            const find = this.containerOrdersTemp.findIndex((d)=> d.ID === e.data.ID );
            console.log(find)
            if(find > -1)
                this.containerOrdersRemove.push(e.data);
        },
        async submit() {
            this.jobOrderTemp.Agent = this.jobOrder.Agent;
            this.jobOrderTemp.Customer = this.jobOrder.Customer;
            this.jobOrderTemp.POD = this.jobOrder.POD;
            this.jobOrderTemp.POL = this.jobOrder.POL;
            this.jobOrderTemp.VesselName = this.jobOrder.VesselName;
            for(const jcr of this.jobOrderCostRemove){
                const id = jcr.ID;
                const findIndex = this.jobOrderTemp.ListJobOrderCost.findIndex((d)=> d.ID === id);
                this.jobOrderTemp.ListJobOrderCost[findIndex].DeletedBy = "System";
                this.jobOrderTemp.ListJobOrderCost[findIndex].Deleted = true;
            }
            for(const cor of this.containerOrdersRemove){
                const id = cor.ID;
                const findIndex =  this.jobOrderTemp.ListContainerOrder.findIndex((d)=> d.ID === id);
                this.jobOrderTemp.ListContainerOrder[findIndex].DeletedBy = "System";
                this.jobOrderTemp.ListContainerOrder[findIndex].Deleted = true;
            }
            await this.axios.put(`http://localhost:3003/job-order/${this.jobOrderTemp.ID}`,  this.jobOrderTemp);
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        const response = await this.axios.get(`http://localhost:3003/job-order/detail/${id}`)
        const jobOrder = response.data
        this.jobOrderTemp = jobOrder;
        this.jobOrder.Agent = jobOrder.Agent;
        this.jobOrder.Customer = jobOrder.Customer;
        this.jobOrder.POD = jobOrder.POD;
        this.jobOrder.POL = jobOrder.POL;
        this.jobOrder.VesselName = jobOrder.VesselName;
        this.containerOrders =  Object.assign([],jobOrder.ListContainerOrder);
        this.jobOrderCosts = Object.assign([],jobOrder.ListJobOrderCost);
        this.jobOrderCostsTemp = Object.assign([], this.jobOrderCosts);
        this.containerOrdersTemp = Object.assign([], this.containerOrders);
    }
};
</script>
<style scoped>
.widget-container {
    margin-right: 320px;
    padding: 20px;
    max-width: 550px;
    min-width: 300px;
}

#form {
    margin-top: 25px;
}

.options {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 260px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}
</style>
  