<template>
    <div style="padding: 10px">
        <p>Purchase Request</p>
        <DataGrid Key="ID" ModuleName="Purchase" LoadURL="/Request" :Height="710" :EnableMasterDetail="true"
            :CustomAddButton="true"
            :CustomAddButtonCallBack="CustomAddButtonCallBack" :EnableSearch="true"
            :OnStartEditing="EditingStart"
        >
            <template v-slot:Columns>
                <DxColumn caption="PR Type" data-field="PRTypeID"  calculate-display-value="PurchaseRequestType.Name">
                    <DxRequiredRule message="The PRType field is required." />
                    <DxLookup
                        :remote-operations="true"
                        :data-source="PRTypeDS"
                        value-expr="ID"
                        display-expr="Name"
                    />
                </DxColumn>
                <DxColumn data-field="PRNumber">
                    <DxRequiredRule message="The PRNumber field is required." />
                </DxColumn>
                <DxColumn data-field="PRDate"  data-type="date">
                    <DxRequiredRule message="The PRDate field is required." />
                </DxColumn>
                <DxColumn data-field="RequireDate" data-type="date">
                    <DxRequiredRule message="The RequireDate field is required." />
                </DxColumn>
                <DxColumn data-field="RequestBy">
                    <DxRequiredRule message="The RequestBy field is required." />
                    <DxLookup
                        :remote-operations="true"
                        :data-source="EmployeeDS"
                        value-expr="ID"
                        display-expr="Name"
                    />
                </DxColumn>
                <DxColumn data-field="Importance" >
                    <DxRequiredRule message="The Importance field is required." />
                    <DxLookup
                        :data-source="['LOW','NORMAL','HIGH']"
                    />
                </DxColumn>
                <DxColumn data-field="Remarks">
                    <DxRequiredRule message="The Remarks field is required." />
                </DxColumn>
                <DxColumn data-field="ExternalRef">
                    <DxRequiredRule message="The ExternalRef field is required." />
                </DxColumn>
                <DxColumn data-field="Purpose">
                    <DxRequiredRule message="The Purpose field is required." />
                </DxColumn>
            </template>
            <template #MasterDetail="{ data: PR }">
                <MasterDetailGrid :template-data="PR" @reload="onReload" />
            </template>
        </DataGrid>
    </div>
</template>
<script>
import DataGrid from '@/components/DatagridView-Component.vue'
import {
    DxColumn,
} from 'devextreme-vue/data-grid';
import MasterDetailGrid from '../purchase-request-detail.vue'
export default {
    components: {
        DataGrid,
        DxColumn,
        MasterDetailGrid
    },
    data() {
        return {
            title: 'AP - Purchase Request'
        };
    },
    methods: {
        CustomAddButtonCallBack() {
            this.$router.push({name: 'PurchaseRequestNew'});
        }
    },
    mounted() {
        document.title = this.title;
    }
};
</script>
  