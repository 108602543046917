<template>
    <div style="padding: 10px">
        <p>Role</p>
        <DataGrid ref="grid"
        ModuleName="Role"
        LoadURL="/"
        RemoveURL="/"
        UpdateURL="/"
        InsertURL="/"
        :height="730">
        <template v-slot:Columns>
            <DxColumn data-field="Name">
                <DxRequiredRule message="The PackingListNo field is required." />
            </DxColumn>
            <DxColumn data-field="Description">
                <DxRequiredRule message="The PackingListNo field is required." />
            </DxColumn>
            </template>
        </DataGrid>
    </div>
</template>
<script>
import DataGrid from '@/components/DatagridView-Component.vue'
import {
    DxColumn
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { WebAPIHost } from '../../utils/config'
const url = `${WebAPIHost}/Authentication/Role`;
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});

export default {
    components: {
        DataGrid,
        DxColumn
    },
    data() {
        return {
            url,
            dataSource,
        };
    },
    methods: {
        gotoAdd() {
            this.$router.push("/packing-list/new")
        }
    },
    mounted() {
        console.log(this.$refs)
    }
};
</script>
  