export default [
    {
        text: "Expense",
        icon: "folder",
        items: [
          {
            text: "New",
            path: "/expense/new"
          },
          {
            text: "List",
            path: "/expense"
          }
        ]
    },
    {
        text: "Expense Type",
        icon: "folder",
        items: [
          {
            text: "New",
            path: "/expense-type/new"
          },
          {
            text: "List",
            path: "/expense-tye"
          }
        ]
      },
]