export function GetContextMenu(module, cb) {
  switch (module) {
    case 'Authentication/User':
      return  [{
        text: 'Privilege',
        icon: 'key',
        items: [
          { 
            text: 'Role',
            onClick: ()=> cb('Role')
          },
          { 
            text: 'Role Group',
            onClick: ()=> cb('Privilege/RoleGroup')
          }],
      },
      {
        text: 'Profile',
        icon: 'user',
        items: [
          { 
            text: 'Data',
            onClick: ()=> cb('Profile/Data')
          },
          { 
            text: 'History',
            onClick: ()=> cb('Profile/History')
          }],
      },
      {
        text: 'Access Logs',
        icon: 'chart',
        onClick: ()=> cb('AccessLogs')
      }];
    case 'UserLogs':
      return  [{
        text: 'Kill',
        icon: 'close',
        onClick: ()=> cb('UserLogs/Kill')
      }
     ];
    default: []
      break;
  }
}
