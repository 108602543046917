import appInfo from './app-info';
const defaultUser = {
  email: 'sandra@example.com',
  avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
};

export default {
  _user: defaultUser,
  loggedIn() {
    return !!localStorage.getItem(appInfo.Conf.Token);
  },

  async logIn(UserName, Password) {
    try {
      const response = await fetch(`${appInfo.WebAPIHost}/Authentication/Login`,
       {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            Username: UserName,
            Password: Password
          })
      })
      if(response.ok) {
        const resp = await response.json();
        localStorage.setItem(appInfo.Conf.Token, resp.Token)
        return {
          isOk: true,
          data: response
        };
      }
      return {
        isOk: false,
        message: "Authentication failed"
      };
      // .then((response) => response.json())
      // console.log(response.data)
      // this._user = {};
      // this._user.email = response.UserName;
      // localStorage.setItem("Sinarjaya-Token", response.Token);
      // this._user.avatarUrl =  'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png';
      // return {
      //   isOk: true,
      //   data: response
      // };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    this._user = null;
    localStorage.clear();
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(Username, Password) {
    try {
      const response = await fetch(`${appInfo.WebAPIHost}/Authentication/Register`,
      {
         method: 'POST',
         headers: {
             'Content-Type': 'application/json'
         },
         body: JSON.stringify({
          Identifier: Username,
           Password
         })
     })
      
      if(response.ok){
        return {
          isOk: true 
        };
      } else {
        return {
          isOk: false,
          message: "Failed to create account"
        };
      }

    }
    catch (e){
      console.log(e)
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
