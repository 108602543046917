export default [
    {
        text: "Master",
        icon: "box",
        items: [
            {
                text: "Category",
                path: "/product-category"
              },
              {
                text: "Brand",
                path: "/brand"
              },
              {
                text: "UOM",
                path: "/UOM"
              },
              {
                text: "Supplier",
                path: "/supplier"
              },
              {
                text: "Customer",
                path: "/customer"
              },
              {
                text: "Variant Class",
                path: "/variant-class"
              },
              {
                text: "Vehicle Type",
                path: "/vehicle-type"
              },
              {
                text: "Variant Status",
                path: "/variant-status"
              }
              
        ]
    }
]