export default [
    {
        text: "Purchase",
        icon: "box",
        items: [
            {
                text: "Purchase Request Type",
                path: "/purchase/request-type"
            },
            {
                text: "Purchase Request",
                path: "/purchase/request"
            },
            // {
            //   text: "Quotation",
            //   path: "/quotation"
            // },
            {
                text: "Purchase Order",
                path: "/purchase/order"
            },
            {
                text: "Purchase Invoice",
                path: "/purchase/invoice"
            },
            {
                text: "Goods Received Note",
                path: "/purchase/gr"
            },

        ]
    }
]