<template>
    <div style="padding: 10px">
        <p>User</p>
        <Datagrid :MenuContextCallBack="MenuContextCallBack" ContextMenuName="Authentication/User"
            ModuleName="Authentication" LoadURL="/User" InsertURL="/User" ref="grid" :show-borders="true"
            :remote-operations="true" :height="730"> <template v-slot:Columns>
                <DxColumn data-field="Identifier">
                    <DxRequiredRule message="The Username field is required." />
                </DxColumn>
                <DxColumn data-field="Password">
                    <DxRequiredRule message="The Password field is required." />
                </DxColumn>
            </template>
        </Datagrid>
    </div>
</template>
<script>
import {
    DxColumn,
    DxRequiredRule,
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { WebAPIHost } from '../../utils/config'
import Datagrid from '../../components/DatagridView-Component.vue'

const url = `${WebAPIHost}/Authentication/User`;
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        console.log(key)
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});
const roleGroupDS = createStore({
    key: 'ID',
    loadUrl: `${WebAPIHost}/Authentication/RoleGroup`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
const roleDS = createStore({
    key: 'ID',
    loadUrl: `${WebAPIHost}/Authentication/Role`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
    },
});
export default {
    components: {
        Datagrid,
        DxColumn,
        DxRequiredRule,
    },
    data() {
        return {
            url,
            dataSource,
            roleGroupDS,
            roleDS
        };
    },
    methods: {
        gotoAdd() {
            this.$router.push("/packing-list/new")
        },
        MenuContextCallBack(contextName, data) {
            console.log(data)
            // const token = this.$CryptoJS.AES.encrypt(data.UserName, this.$appInfo.PrivateKey).toString();
            switch (contextName) {
                case 'Role':
                    this.$router.push({ name: 'RoleGroup'});
                    break;
            }
        }
    }
};
</script>
  