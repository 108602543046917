<template>
    <div style="padding: 10px">
        <DataGrid PageTitle="Supplier" Key="ID" ModuleName="Supplier" LoadURL="/List" InsertURL="/Create"
            UpdateURL="/Modify" RemoveURL="/Delete" :DeletePermanent="false" :Height="730" :EnableSearch="true"
            :FilterStatus="CustomFilter" ContextMenuName="UOM" :MenuContextCallBack="MenuContextCallBack">
            <template v-slot:Columns>
                <DxColumn data-field="Name" />
                <DxColumn data-field="Description" />
                <DxColumn data-field="Address" />
                <DxColumn data-field="MobileNumber" />
                <DxColumn data-field="City" />
                <DxColumn data-field="ContactPerson" />
                <DxColumn data-field="Email" />
                <DxColumn data-field="Country" />
                <DxColumn data-field="Logo" />
                <DxColumn data-field="Website" />
                <DxColumn data-field="Phone" />
                <DxColumn data-field="CreatedBy" :min-width="107"></DxColumn>
                <DxColumn data-field="CreatedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
                <DxColumn data-field="ModifiedBy" :min-width="107"></DxColumn>
                <DxColumn data-field="ModifiedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
                <DxColumn data-field="DeletedBy" :min-width="107"></DxColumn>
                <DxColumn data-field="DeletedTime" data-type="date" format="dd/MM/yyyy hh:mm"></DxColumn>
            </template>
        </DataGrid>
    </div>
</template>
<script>
import { DxColumn } from 'devextreme-vue/data-grid';
import DataGrid from '../../components/DatagridView-Component.vue'
export default {
    components: {
        DataGrid,
        DxColumn
    },
    data() {
        return {
            APIHost: this.$appInfo.WebAPIHost,
        };
    },
    methods: {
        MenuContextCallBack(contextName, data) {
            const token = this.$CryptoJS.AES.encrypt(data.ID.toString(), this.$appInfo.PrivateKey).toString();
            switch (contextName) {
                case 'ExtMemo':
                    this.$router.push({ name: 'ExtMemo', query: { U: token } });
                    break;
                case 'IntMemo':
                    this.$router.push({ name: 'IntMemo', query: { U: token } });
                    break;
            }
        },
    },
    async mounted() {
    },
};
</script>
  