import auth from "./auth";
import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/home-page";
import Profile from "@/views/profile-page";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import JobOrder from "@/views/job-order/job-order-page"
import JobOrderNew from "@/views/job-order/new-page"
import JobOrderOutstanding from "@/views/job-order/outstanding/job-order-outstanding-page"
import JobOrderRelease from "@/views/job-order/release/job-order-release-page"
import JobOrderEdit from "@/views/job-order/job-order-edit-page"
import PackingListType from "@/views/packing-list-type/packing-list-type-page"
import Role from "@/views/role/role-page"
import RoleGroup from "@/views/role-group/role-group-page"
import User from "@/views/user/user-page"
import UserRoleGroup from "@/views/user-role-group/user-role-group-page"
import ProductItem from "@/views/product/product-item-page"
import ProductCategory from "@/views/master/product-category-page"
import Brand from "@/views/master/brand-page"
import Supplier from "@/views/master/supplier-page"
import Customer from "@/views/master/customer-page"
import PurchaseRequestType from "@/views/purchase/purchase-request-type-page"
import PurchaseRequest from "@/views/purchase/request/purchase-request-page"
import Quotation from "@/views/purchase/quotation-page"
import PurchaseOrder from "@/views/purchase/order/purchase-order-page"
// import Test from '@/views/test-page'
import Employee from '@/views/hrm/employee-page'
import appInfo from "./app-info";
const Client = () => import (/* webpackChunkName: "client" */'ClientApp/ClientPage')

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `@/views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/purchase/request/new",
      name: "PurchaseRequestNew",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/purchase/request/purchase-request-new.vue`)
    },
    {
      path: "/test",
      name: "Test Page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Client
    },
    {
      path: "/employee",
      name: "Employee",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Employee
    },
    {
      path: "/purchase/request-type",
      name: "PurchaseRequestType",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: PurchaseRequestType
    },
    {
      path: "/purchase/request",
      name: "PurchaseRequest",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: PurchaseRequest
    },
    {
      path: "/purchase/request/new",
      name: "PurchaseRequestNew",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/purchase/request/purchase-request-new.vue`)
    },
    {
      path: "/quotation",
      name: "Quotation",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Quotation
    },
    {
      path: "/purchase/order",
      name: "PurchaseOrder",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: PurchaseOrder
    },
    {
      path: "/purchase/order/new",
      name: "PurchaseOrderNew",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/purchase/order/purchase-order-new.vue`)
    },
    {
      path: "/Purchase/Invoice",
      name: "PurchaseInvoice",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/purchase/invoice/purchase-invoice-page.vue`)
    },
    {
      path: "/Purchase/Invoice/New",
      name: "PurchaseInvoiceNew",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/purchase/invoice/purchase-invoice-new.vue`)
    },

    {
      path: "/supplier",
      name: "supplier",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Supplier
    },
    {
      path: "/customer",
      name: "customer",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Customer
    },
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/user",
      name: "User",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: User
    },
    
    {
      path: "/role",
      name: "Role",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Role
    },
    {
      path: "/role-group",
      name: "RoleGroup",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: RoleGroup
    },
    {
      path: "/testing",
      name: "Testing",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ()=> import("@/views/testing-page.vue")
    },
    {
      path: "/assign-role",
      name: "Assign Role",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UserRoleGroup
    },
    {
      path: "/product-item",
      name: "ProductItem",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ProductItem
    },
    {
      path: "/product-item/detail/:id",
      name: "ProductItemDetail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/product/product-item-detail-view.vue`)
    },
    {
      path: "/product-item/new",
      name: "ProductItemNew",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/product/product-new.vue`)
    },
    {
      path: "/product-item/edit/:id",
      name: "ProductItemEdit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/product/product-edit.vue`)
    },
    {
      path: "/product-category",
      name: "ProductCategory",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ProductCategory
    },
    {
      path: "/product-variant",
      name: "ProductVariant",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/product/product-variant-page.vue`)
    },
    {
      path: "/brand",
      name: "Brand",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Brand
    },
    {
      path: "/uom",
      name: "UOM",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/master/uom-page.vue`)
    },
    {
      path: "/media",
      name: "Media",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/master/media-page.vue`)
    },
    {
      path: "/variant-class",
      name: "VariantClass",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/master/variant-class-page.vue`)
    },
    {
      path: "/variant-status",
      name: "VariantStatus",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/master/product-variant-status-page.vue`)
    },
    {
      path: "/vehicle-type",
      name: "VehicleType",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import (`@/views/master/vehicle-type-page.vue`)
    },
    {
      path: "/packing-list-type",
      name: "PackingListType",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: PackingListType
    },
    {
      path: "/job-order",
      name: "job-order",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: JobOrder
    },
    {
      path: "/job-order/detail/:id",
      name: "job-order-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: JobOrderEdit
    },
    {
      path: "/job-order/new",
      name: "job-order-new",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: JobOrderNew
    },
    {
      path: "/job-order/outstanding",
      name: "job-order-outstanding",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: JobOrderOutstanding
    },
    {
      path: "/job-order/release",
      name: "job-order-release",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: JobOrderRelease
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHistory()
});
router.beforeEach(async (to, from, next) => {
  const userCount = await fetch(`${appInfo.WebAPIHost}/Authentication/UserCount`).then(x=> x.json());
  if(userCount === 0 && to.name !== "create-account"){
    next({name: 'create-account'});
    return;
  }

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
