export default [
    {
        text: "HRM",
        icon: "user",
        items: [
          {
            text: "Employee",
            items: [
              {
                text: "List",
                path: "/employee"
              },
              {
                text: "Leave Request",
                path: "/employee/leave-request"
              },
              {
                text: "Training",
                path: "/employee3"
              },
              {
                text: "Terminate",
                path: "/employee4"
              },
              {
                text: "Attendance",
                path: "/employee5"
              },
            ]
          },
        ]
      },
]