import PurchaseNavigation from './purchase.navigation';
import MasterNavigation from './master.navigation';
import ProductNavigation from './product.navigation';
import SalesNavigation from './sales.navigation';
import ExpenseNavigation from './expense.navigation';
import HRMNavigation from './hrm.navigation';
import UserNavigation from './user.navigation';

export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  ...UserNavigation,
  ...HRMNavigation,
  ...MasterNavigation,
  ...ProductNavigation,
  ...PurchaseNavigation,  
  ...SalesNavigation,
  ...ExpenseNavigation
];
