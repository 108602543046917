export default {
  title: "Sinar Jaya",
  App: "SJ",
  WebAPIHost: "https://api.sj.techluxid.com",
  // WebAPIHost: "http://localhost:5022",
  Conf: {
    Token: "SJ-TOKEN",
    UserName: "SJ-USERNAME"
  }
};
