<template>
    <div style="padding: 10px">
        <p>Role Group</p>
        <Datagrid :EnableMasterDetail="true" ref="grid" 
        ModuleName="Role" LoadURL="/Group" InserURL="/Group"
        RemoveURL="/Group"
        UpdateURL="/Group"
        :height="730">
            <template v-slot:Columns>
                <DxColumn data-field="Name">
                    <DxRequiredRule message="The PackingListNo field is required." />
                </DxColumn>
                <DxColumn data-field="Description">
                    <DxRequiredRule message="The PackingListNo field is required." />
                </DxColumn>
           </template>
            <template #MasterDetail="{ data: roleGroup }">
                <MasterDetailGrid :template-data="roleGroup"   @reload="onReload"/>
            </template>
        </Datagrid>
    </div>
</template>
<script>
import Datagrid from '@/components/DatagridView-Component.vue'
import {
    // DxDataGrid,
    DxColumn,
    // DxEditing,
    // DxHeaderFilter,
    // DxGroupPanel,
    // DxGrouping,
    // DxScrolling,
    // DxSummary,
    // DxTotalItem,
    // DxGroupItem,
    // DxRequiredRule,
    // DxValueFormat,
    // DxMasterDetail
} from 'devextreme-vue/data-grid';
//   import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { WebAPIHost } from '../../utils/config'
import MasterDetailGrid from './role-group-detail.vue';
const url = `${WebAPIHost}/Authentication/RoleGroup`;
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}
const dataSource = new CustomStore({
    key: 'ID',
    load(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary',
        ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);
        return fetch(`${url}${params}`)
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount,
            }))
            .catch(() => { throw new Error('Data Loading Error'); });
    },
    insert(data) {
        return fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    update(key, data) {
        return fetch(`${url}/${key}`, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(() => { throw new Error('Data Loading Error'); });
    },
    remove(key) {
        return fetch(`${url}/${key}`, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json'
            },
            body: null
        }).catch(() => { throw new Error('Data Loading Error'); });
    }
});

export default {
    components: {
        Datagrid,
        DxColumn,
        // DxEditing,
        // DxHeaderFilter,
        // DxGroupPanel,
        // DxGrouping,
        // DxScrolling,
        // DxSummary,
        // DxTotalItem,
        // DxGroupItem,
        // DxRequiredRule,
        // DxValueFormat,
        // DxMasterDetail,
        MasterDetailGrid
    },
    data() {
        return {
            url,
            dataSource,
        };
    },
    methods: {
        gotoAdd() {
            this.$router.push("/packing-list/new")
        },
        onReload() {
            this.$refs.grid.instance.refresh();
        }
    }
};
</script>
  